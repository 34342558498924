import {
  Typography,
  Modal,
  Paper,
  Box,
  Autocomplete,
  TextField,
  Grid,
  IconButton,
  Button,
} from "@mui/material";
import * as d3 from "d3";
import { getAlpha3ByAlpha2, getAlpha2ByAlpha3 } from "country-locale-map";
import CloseIcon from "@mui/icons-material/Close";

import { countriesData } from "./countriesData";
import { getCountries } from "../MatchProfile/utils/getCountries";
import {
  EEC_COUNTRY_CODES,
  EU_COUNTRY_CODES,
  OFACS_COUNTRY_CODES,
} from "../MatchProfile/constants";
import { MatchProfile } from ".";

const countries = getCountries();

const Map = ({
  width,
  height,
  data,
  allowedCountryCodes,
  toggleCountryCode,
}: {
  width: number;
  height: number;
  data: any;
  allowedCountryCodes: string[];
  toggleCountryCode: (countryCode: string) => void;
}) => {
  const projection = d3
    .geoMercator()
    .scale(width / 2 / Math.PI - 40)
    .center([-30, 10]);

  const geoPathGenerator = d3.geoPath().projection(projection);

  const handleClick = (evt: any) => {
    const alpha3 = evt.target.getAttribute("id");
    const alpha2 = getAlpha2ByAlpha3(alpha3);
    if (alpha2) {
      toggleCountryCode(alpha2);
    }
  };

  const allowedAlpha3s = allowedCountryCodes.map((alpha2) =>
    getAlpha3ByAlpha2(alpha2)
  );

  const allSvgPaths = data.features
    .filter((shape: any) => shape.id !== "ATA")
    .map((shape: any, index: number) => {
      const fill = allowedAlpha3s.includes(shape.id) ? "green" : "red";
      return (
        <path
          onClick={handleClick}
          key={shape.id + index}
          d={geoPathGenerator(shape) || undefined}
          stroke="lightGrey"
          strokeWidth={0.5}
          fill={fill}
          fillOpacity={0.7}
          id={shape.id}
        />
      );
    });

  return (
    <Box sx={{ border: "1px solid grey" }}>
      <svg width={width} height={height}>
        {allSvgPaths}
      </svg>
    </Box>
  );
};

function NameList({
  countries,
  toggleCountryCodes,
}: {
  countries: any[];
  toggleCountryCodes: any;
}) {
  const gather: any = {};
  countries.forEach((country: any) => {
    if (!(country.continent in gather)) {
      gather[country.continent] = [];
    }
    gather[country.continent].push(country);
  });
  const continents = Object.keys(gather);
  continents.sort();

  return (
    <Box
      sx={{
        border: "1px solid black",
        p: 1,
        height: "100%",
        "white-space": "pre-wrap",
      }}
    >
      {continents.map((continent: string) => (
        <Box key={continent}>
          <Box>
            <Typography
              variant="subtitle1"
              display="inline-block"
              sx={{ fontWeight: 600, mr: 1 }}
            >
              {continent}
            </Typography>
            <Button
              size="small"
              variant="text"
              sx={{ textTransform: "none" }}
              onClick={() =>
                toggleCountryCodes(
                  gather[continent].map((ct: any) => ct.alpha2)
                )
              }
            >
              move all
            </Button>
          </Box>
          {gather[continent].toSorted().map((country: any) => (
            <Box
              key={country.alpha2}
              display={"inline-block"}
              sx={{
                border: "1px solid lightGrey",
                borderRadius: 2,
                m: 0.5,
                pt: 0,
                pb: 0,
                pr: 1,
                pl: 1,
                cursor: "pointer",
                "&:hover": { backgroundColor: "lightGrey" },
              }}
              onClick={() => toggleCountryCodes([country.alpha2])}
            >
              <Typography variant="body2">{country.name}</Typography>
            </Box>
          ))}
        </Box>
      ))}
    </Box>
  );
}

function CountrySelect({
  countryCodesCallback,
}: {
  countryCodesCallback: any;
}) {
  const options = countries.map((c: any) => ({ label: c.name, id: c.alpha2 }));
  options.unshift({ label: "European Econonomic Community", id: "eec" });
  options.unshift({ label: "European Union", id: "europeanunion" });
  options.unshift({ label: "OFACS", id: "ofacs" });
  options.unshift({ label: "South America", id: "southamerica" });
  options.unshift({ label: "Oceana", id: "oceana" });
  options.unshift({ label: "North America", id: "northamerica" });
  options.unshift({ label: "Europe", id: "europe" });
  options.unshift({ label: "Asia", id: "asia" });
  options.unshift({ label: "Africa", id: "africa" });
  options.unshift({ label: "All Countries", id: "allcountries" });

  const africaCountryCodes = countries
    .filter((country) => {
      return country.continent.toLowerCase() === "africa";
    })
    .map((country) => {
      return country.alpha2;
    });
  africaCountryCodes.push("SS");

  const northAmericaCountryCodes = countries
    .filter((country) => {
      return country.continent.toLowerCase() === "north america";
    })
    .map((country) => {
      return country.alpha2;
    });
  const southAmericaCountryCodes = countries
    .filter((country) => {
      return country.continent.toLowerCase() === "south america";
    })
    .map((country) => {
      return country.alpha2;
    });
  const europeCountryCodes = countries
    .filter((country) => {
      return country.continent.toLowerCase() === "europe";
    })
    .map((country) => {
      return country.alpha2;
    });
  const asiaCountryCodes = countries
    .filter((country) => {
      return country.continent.toLowerCase() === "asia";
    })
    .map((country) => {
      return country.alpha2;
    });
  const oceaniaCountryCodes = countries
    .filter((country) => {
      return country.continent.toLowerCase() === "oceania";
    })
    .map((country) => {
      return country.alpha2;
    });
  const allCountryCodes = countries.map((c) => c.alpha2);
  allCountryCodes.push("SS");

  const handleSelect = (optionId: string) => {
    switch (optionId) {
      case "allcountries": {
        countryCodesCallback(countries.map((c) => c.alpha2));
        break;
      }
      case "eec": {
        countryCodesCallback(EEC_COUNTRY_CODES);
        break;
      }
      case "ofacs": {
        countryCodesCallback(OFACS_COUNTRY_CODES);
        break;
      }
      case "europeanunion": {
        countryCodesCallback(EU_COUNTRY_CODES);
        break;
      }
      case "africa": {
        countryCodesCallback(africaCountryCodes);
        break;
      }
      case "europe": {
        countryCodesCallback(europeCountryCodes);
        break;
      }
      case "northamerica": {
        countryCodesCallback(northAmericaCountryCodes);
        break;
      }
      case "southamerica": {
        countryCodesCallback(southAmericaCountryCodes);
        break;
      }
      case "asia": {
        countryCodesCallback(asiaCountryCodes);
        break;
      }
      case "oceana": {
        countryCodesCallback(oceaniaCountryCodes);
        break;
      }
      default: {
        countryCodesCallback([optionId]);
        break;
      }
    }
  };

  return (
    <Box display="inline-block" width="300px">
      <Autocomplete
        onChange={(evt, value) => handleSelect(value?.id)}
        size="small"
        options={options}
        sx={{ width: 300 }}
        renderInput={(params) => (
          <TextField {...params} label="Add Countries or Country Groups" />
        )}
        isOptionEqualToValue={(option, value) => option.id === value.id}
      />
    </Box>
  );
}

export default function LocationModal({
  open,
  setOpenModal,
  matchProfile,
  updateMatchProfile,
}: {
  open: boolean;
  setOpenModal: (arg: string | null) => void;
  matchProfile: MatchProfile;
  updateMatchProfile: any;
}) {
  const blockedCountries = countries.filter((country) => {
    return !matchProfile.allowedCountryCodes.includes(country.alpha2);
  });

  const allowedCountries = countries.filter((country) => {
    return matchProfile.allowedCountryCodes.includes(country.alpha2);
  });

  const toggleCountryCode = (countryCode: string) => {
    const newAllowed = [...matchProfile.allowedCountryCodes];
    const index = newAllowed.indexOf(countryCode);
    if (index !== -1) {
      newAllowed.splice(index, 1);
    } else {
      newAllowed.push(countryCode);
    }
    newAllowed.sort();
    updateMatchProfile({ allowedCountryCodes: newAllowed });
  };

  const allowCountryCodes = (countryCodes: string[]) => {
    const newAllowed = [...matchProfile.allowedCountryCodes];
    const deduped = [...new Set(newAllowed.concat(countryCodes))];
    deduped.sort();
    updateMatchProfile({ allowedCountryCodes: deduped });
  };

  const blockCountryCodes = (countryCodes: string[]) => {
    const newAllowed = [...matchProfile.allowedCountryCodes].filter(
      (cc) => !countryCodes.includes(cc)
    );
    newAllowed.sort();
    updateMatchProfile({ allowedCountryCodes: newAllowed });
  };

  return (
    <>
      <Modal
        open={open}
        onClose={() => setOpenModal(null)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Paper
          elevation={2}
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 1200,
            height: "90vh",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            overflow: "scroll",
          }}
        >
          <Box marginBottom={4} position="relative">
            <IconButton
              onClick={() => setOpenModal(null)}
              sx={{ position: "absolute", top: -20, right: -15 }}
            >
              <CloseIcon sx={{ fontSize: 50 }} />
            </IconButton>
            <Typography variant="h5" component="h3" sx={{ mb: 3 }}>
              Countries
            </Typography>
            <Map
              width={1200}
              height={400}
              data={countriesData}
              allowedCountryCodes={matchProfile.allowedCountryCodes}
              toggleCountryCode={toggleCountryCode}
            />
            <Grid container spacing={6} sx={{ mb: 8, mt: -3 }}>
              <Grid item xs={12} sm={6}>
                <Box sx={{ mb: 2 }}>
                  <Typography
                    variant="subtitle1"
                    display="inline-block"
                    sx={{ mr: 3 }}
                  >
                    Allowed Countries
                  </Typography>
                  <CountrySelect countryCodesCallback={allowCountryCodes} />
                </Box>
                <NameList
                  countries={allowedCountries}
                  toggleCountryCodes={blockCountryCodes}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box sx={{ mb: 2 }}>
                  <Typography
                    variant="subtitle1"
                    display="inline-block"
                    sx={{ mr: 3 }}
                  >
                    Blocked Countries
                  </Typography>
                  <CountrySelect countryCodesCallback={blockCountryCodes} />
                </Box>
                <NameList
                  countries={blockedCountries}
                  toggleCountryCodes={allowCountryCodes}
                />
              </Grid>
            </Grid>
          </Box>
        </Paper>
      </Modal>
    </>
  );
}
