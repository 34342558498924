import { createColumnHelper } from "@tanstack/react-table";
import { Badge, Checkbox } from "@mui/material";
import { capitalize, sortBy, uniq } from "lodash";
import { parseISO, format } from "date-fns";

import type { SelectionAction, TableColumn, TableRow } from "./types";
import RaggedTextSkeleton from "./RaggedTextSkeleton";
import SelectColumn, {
  ColumnHeader as SelectColumnHeader,
} from "./SelectColumn";

export const DEFAULT_COLUMNS: TableColumn[] = [
  "domain",
  "status",
  "date",
  "alerts",
  "assignee",
];
export const DEFAULT_SELECTION_ACTIONS: SelectionAction[] = [
  "assign",
  "export",
];

export const columnHelper = createColumnHelper<TableRow>();

export const columnDefinitions: Record<
  TableColumn | "_select",
  { whenLoading: any; otherwise: any }
> = {
  _select: {
    whenLoading: {
      id: "select",
      header: () => <SelectColumnHeader disabled />,
      cell: () => <Checkbox disabled />,
    },
    otherwise: new SelectColumn<TableRow>(),
  },
  alerts: {
    whenLoading: {
      id: "alerts",
      header: "Alerts",
      cell: () => <RaggedTextSkeleton />,
      meta: {
        resizable: true,
      },
    },
    otherwise: columnHelper.accessor("alerts", {
      id: "alerts",
      meta: {
        filterVariant: "valuePicker",
        sortVariant: "simple",
        resizable: true,
      },
      cell: ({ cell }) =>
        sortBy(uniq(cell.getValue())).map(capitalize).join(", "),
    }),
  },
  assignee: {
    whenLoading: {
      id: "assignee",
      header: "Assignee",
      cell: () => <RaggedTextSkeleton />,
      meta: {
        resizable: true,
      },
    },
    otherwise: columnHelper.accessor("assignee", {
      id: "assignee",
      meta: {
        filterVariant: "valuePicker",
        filterStickyValues: ["unassigned"],
        sortVariant: "simple",
        resizable: true,
      },
      cell: ({ cell, row }) => {
        const value = cell.getValue();
        const assignee = value?.email;

        const showBadge = !assignee && row.getValue("status") !== "Resolved";

        return (
          <Badge
            color="primary"
            variant="dot"
            badgeContent={showBadge ? 1 : 0}
            sx={{
              "& .MuiBadge-badge": {
                top: "50%",
                right: "-0.75em",
              },
            }}
          >
            {assignee || "Unassigned"}
          </Badge>
        );
      },
    }),
  },
  date: {
    whenLoading: {
      id: "date",
      header: "Date",
      cell: () => <RaggedTextSkeleton />,
      meta: {
        resizable: true,
      },
    },
    otherwise: columnHelper.accessor("date", {
      id: "date",
      cell: ({ cell }) => {
        const timestamp = cell.getValue();
        try {
          const parsed = parseISO(timestamp);
          return format(parsed, "MMM d, yyyy h:mm a");
        } catch (e) {
          return timestamp;
        }
      },
      meta: {
        filterVariant: "dateThreshold",
        sortVariant: "simple",
        resizable: true,
      },
    }),
  },
  domain: {
    whenLoading: {
      id: "domain",
      header: "Domain",
      cell: () => <RaggedTextSkeleton />,
      meta: {
        resizable: true,
      },
    },
    otherwise: columnHelper.accessor("domain", {
      id: "domain",
      meta: {
        filterVariant: "valuePicker",
        sortVariant: "simple",
        resizable: true,
      },
    }),
  },
  status: {
    whenLoading: {
      id: "status",
      header: "Status",
      cell: () => <RaggedTextSkeleton />,
      meta: {
        resizable: true,
      },
    },
    otherwise: columnHelper.accessor("status", {
      id: "status",
      meta: {
        filterVariant: "valuePicker",
        sortVariant: "simple",
        resizable: true,
      },
    }),
  },
  id: {
    whenLoading: {
      id: "id",
      header: "Id",
      cell: () => <RaggedTextSkeleton />,
      meta: {
        resizable: true,
      },
    },
    otherwise: columnHelper.accessor("id", {
      id: "id",
      meta: {
        sortVariant: "simple",
        resizable: true,
      },
    }),
  },
};
