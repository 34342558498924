import { useEffect, useMemo, useState } from "react";
import {
  useReactTable,
  getCoreRowModel,
  flexRender,
} from "@tanstack/react-table";

import type {
  ColumnFiltersState,
  SortingState,
  RowSelectionState,
  RowData,
} from "@tanstack/react-table";

import {
  useQuery,
  keepPreviousData,
  useQueryClient,
  useMutation,
} from "@tanstack/react-query";

import {
  Button,
  CircularProgress,
  Link,
  Pagination,
  Stack,
  Typography,
} from "@mui/material";

import type { StackProps } from "@mui/material/Stack";

import { isEmpty, isEqual, sortBy, uniq } from "lodash";
import cn from "classnames";

import AddIcon from "@mui/icons-material/Add";
import MenuOpenOutlinedIcon from "@mui/icons-material/MenuOpenOutlined";

import { useSearchParams } from "react-router-dom";
import GlobalFilter from "./GlobalFilter";
import ExportButton from "./ExportButton";
import AssignButton from "./AssignButton";
import ColumnFilterSort from "./ColumnFilterSort";
import SimpleSort from "./ColumnFilterSort/SimpleSort";
import ValuePickerFilter from "./ColumnFilterSort/ValuePickerFilter";
import DateThresholdFilter, {
  DateThresholdComparator,
  DateThresholdFilterValue,
} from "./ColumnFilterSort/DateThresholdFilter";

import type {
  SelectionAction,
  TableColumn,
  TableOrderBy,
  TableFilterBy,
  TableRow,
} from "./types";

import {
  columnDefinitions,
  DEFAULT_COLUMNS,
  DEFAULT_SELECTION_ACTIONS,
} from "./columnDefinitions";
import styles from "./Table.module.css";
import OverflowTooltip from "./OverflowTooltip";
import { useActiveMonitorsDrawer } from "../ActiveMonitorsDrawer";
import { APINotificationStatus } from "../../../types/APINotification";
import { useShowNetworkFailureToast } from "../../NetworkFailureToast";

// This feels hacky as fuck, but is the officially sanctioned way
// of getting the types to work. Woe to you who is trying to
// have multiple table instances with conflicting column defs ¯\_(ツ)_/¯
declare module "@tanstack/react-table" {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface ColumnMeta<TData extends RowData, TValue> {
    filterVariant?: "valuePicker" | "dateThreshold";
    filterStickyValues?: string[];
    sortVariant?: "simple";
    resizable?: boolean;
  }
}

export const DEFAULT_PAGE_SIZE = 15;
export const DEFAULT_DESCRIPTION = "issues";

export interface Props extends StackProps {
  getIssues: (config: {
    limit: number;
    offset: number;
    orderBy: TableOrderBy[];
    filterBy: TableFilterBy[];
    fuzzySearch: string;
  }) => Promise<{
    items: TableRow[];
    count: number;
    limit: number;
    offset: number;
  }>;

  getIssuesByIds: (ids: string[]) => Promise<{ issues: TableRow[] }>;

  getUniqueValuesForDomain?: () => Promise<string[]>;
  getUniqueValuesForStatus?: () => Promise<string[]>;
  getUniqueValuesForAlerts?: () => Promise<string[]>;
  getUniqueValuesForAssignees?: () => Promise<string[]>;

  onAssignIssuesToUser?: ({
    issueIds,
    userId,
  }: {
    issueIds: UUID[];
    userId: UUID | null | undefined;
    userEmail: string | null | undefined;
  }) => Promise<void>;
  onOptimisticallyUpdateIssuesAfterAssignment?: ({
    issueIds,
    userId,
    userEmail,
    items,
  }: {
    issueIds: UUID[];
    userId: UUID | null | undefined;
    userEmail: string | null | undefined;
    items: TableRow[];
  }) => TableRow[];
  onAssignIssuesToUserSuccess?: ({
    issueIds,
    userId,
  }: {
    issueIds: UUID[];
    userId: UUID | null | undefined;
  }) => Promise<void>;
  onAssignIssuesToUserError?: ({
    issueIds,
    userId,
  }: {
    issueIds: UUID[];
    userId: UUID | null | undefined;
  }) => Promise<void>;

  onOpenIssueDetail?: (issueId: UUID, monitorId?: UUID) => void;

  prefetch?: () => void;
  description?: string;
  columns?: TableColumn[];
  initialFilters?: TableFilterBy[];
  selectable?: boolean;
  selectionActions?: SelectionAction[];
  pageSize?: number;

  deEmphasizeResolvedIssues?: boolean;
}

export type PrefetchProps = Pick<
  Props,
  | "getIssues"
  | "getUniqueValuesForAlerts"
  | "getUniqueValuesForAssignees"
  | "getUniqueValuesForDomain"
  | "getUniqueValuesForStatus"
  | "pageSize"
  | "columns"
  | "description"
  | "initialFilters"
>;

export function Prefetch({
  getIssues,
  getUniqueValuesForAlerts,
  getUniqueValuesForAssignees,
  getUniqueValuesForDomain,
  getUniqueValuesForStatus,
  pageSize = DEFAULT_PAGE_SIZE,
  columns = DEFAULT_COLUMNS,
  description = DEFAULT_DESCRIPTION,
  initialFilters = [],
}: PrefetchProps) {
  const queryClient = useQueryClient();

  // initial state
  const [pagination] = useState({ pageSize, pageIndex: 0 });
  const globalFilter = "";
  const [sorting] = useState<SortingState>([]);
  const [columnFilters] = useState<ColumnFiltersState>(
    initialFilters.map((initialFilter) => {
      let value: TableFilterBy["value"] | DateThresholdFilterValue =
        initialFilter.value;

      if (
        columnDefinitions[initialFilter.column]?.otherwise.meta
          ?.filterVariant === "dateThreshold"
      ) {
        value = {
          cutoffDate: initialFilter.value as Date,
          comparator: initialFilter.operator as DateThresholdComparator,
        };
      }

      return {
        id: initialFilter.column,
        value,
      };
    })
  );

  queryClient.prefetchQuery({
    queryKey: [
      description,
      "issues",
      "uniqueValuesForFilterPickerColumns",
      columns,
    ],
    queryFn: async () => {
      const [domain, status, alerts, assignee] = await Promise.all([
        getUniqueValuesForDomain?.(),
        getUniqueValuesForStatus?.(),
        getUniqueValuesForAlerts?.(),
        getUniqueValuesForAssignees?.(),
      ]);
      return {
        domain: domain || [],
        status: status || [],
        alerts: alerts || [],
        assignee: assignee || [],
      };
    },
  });

  queryClient.prefetchQuery({
    queryKey: [
      description,
      "issues",
      "list",
      pagination,
      sorting,
      columnFilters,
      globalFilter,
    ],
    queryFn: () =>
      getIssues({
        limit: pagination.pageSize,
        offset: pagination.pageIndex * pagination.pageSize,
        orderBy: sorting.map((col) =>
          col.desc ? `-${col.id}` : col.id
        ) as TableOrderBy[],
        filterBy: columnFilters.map((filter) => {
          const filterVariant =
            columnDefinitions[filter.id as TableColumn].otherwise?.meta
              ?.filterVariant;

          let value = filter.value;
          let operator = "eq";

          if (filterVariant === "valuePicker") {
            operator = "in";
          }

          if (filterVariant === "dateThreshold") {
            operator = (filter.value as any).comparator;
            value = (filter.value as any).cutoffDate;
          }

          return { column: filter.id, value, operator } as TableFilterBy;
        }),
        fuzzySearch: globalFilter,
      }),
  });

  return null;
}

export default function IssuesTable({
  getIssues,
  getIssuesByIds,

  getUniqueValuesForDomain,
  getUniqueValuesForStatus,
  getUniqueValuesForAlerts,
  getUniqueValuesForAssignees,

  onAssignIssuesToUser,
  onOptimisticallyUpdateIssuesAfterAssignment,
  onAssignIssuesToUserSuccess,
  onAssignIssuesToUserError,

  onOpenIssueDetail,

  description = DEFAULT_DESCRIPTION,
  columns = DEFAULT_COLUMNS,
  initialFilters = [],
  selectable = true,
  selectionActions = DEFAULT_SELECTION_ACTIONS,
  pageSize = DEFAULT_PAGE_SIZE,
  deEmphasizeResolvedIssues = false,
  sx = {},
  ...etc
}: Props) {
  const queryClient = useQueryClient();
  const showToast = useShowNetworkFailureToast();
  const [params, setParams] = useSearchParams();

  const { showMonitorList, createMonitor } = useActiveMonitorsDrawer();

  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});
  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize });
  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>(
    initialFilters.map((initialFilter) => {
      let value: TableFilterBy["value"] | DateThresholdFilterValue =
        initialFilter.value;

      if (
        columnDefinitions[initialFilter.column]?.otherwise.meta
          ?.filterVariant === "dateThreshold"
      ) {
        value = {
          cutoffDate: initialFilter.value as Date,
          comparator: initialFilter.operator as DateThresholdComparator,
        };
      }

      return {
        id: initialFilter.column,
        value,
      };
    })
  );
  const [globalFilter, setGlobalFilter] = useState(params.get("search") || "");

  useEffect(() => {
    setParams((p) => {
      if (!globalFilter) {
        p.delete("search");
      } else {
        p.set("search", globalFilter);
      }

      return p;
    });
  }, [globalFilter, setParams]);

  const listQuery = useQuery({
    queryKey: [
      description,
      "issues",
      "list",
      pagination,
      sorting,
      columnFilters,
      globalFilter,
    ],
    queryFn: () =>
      getIssues({
        limit: pagination.pageSize,
        offset: pagination.pageIndex * pagination.pageSize,
        orderBy: sorting.map((col) =>
          col.desc ? `-${col.id}` : col.id
        ) as TableOrderBy[],
        filterBy: columnFilters.map((filter) => {
          const filterVariant =
            columnDefinitions[filter.id as TableColumn].otherwise?.meta
              ?.filterVariant;

          let value = filter.value;
          let operator = "eq";

          if (filterVariant === "valuePicker") {
            operator = "in";
          }

          if (filterVariant === "dateThreshold") {
            operator = (filter.value as any).comparator;
            value = (filter.value as any).cutoffDate;
          }

          return { column: filter.id, value, operator } as TableFilterBy;
        }),
        fuzzySearch: globalFilter,
      }),
    placeholderData: keepPreviousData,
    throwOnError: true,
  });

  const uniqueValuesQuery = useQuery({
    queryKey: [
      description,
      "issues",
      "uniqueValuesForFilterPickerColumns",
      columns,
    ],
    queryFn: async () => {
      const [domain, status, alerts, assignee] = await Promise.all([
        getUniqueValuesForDomain?.(),
        getUniqueValuesForStatus?.(),
        getUniqueValuesForAlerts?.(),
        getUniqueValuesForAssignees?.(),
      ]);
      return {
        domain: domain || [],
        status: status || [],
        alerts: alerts || [],
        assignee: assignee || [],
      };
    },
  });

  const assignIssueToUsersMutation = useMutation({
    mutationFn: async ({
      issueIds,
      userId,
      userEmail,
    }: {
      issueIds: string[];
      userId: string | null | undefined;
      userEmail: string | null | undefined;
    }) => {
      if (!onAssignIssuesToUser) return;
      await onAssignIssuesToUser({ issueIds, userId, userEmail });
    },
    mutationKey: ["issues", "assignIssuesToUser"],
    onMutate: async (variables) => {
      if (!onOptimisticallyUpdateIssuesAfterAssignment) return;

      await queryClient.cancelQueries({
        queryKey: [
          description,
          "issues",
          "list",
          pagination,
          sorting,
          columnFilters,
          globalFilter,
        ],
      });
      const previous = queryClient.getQueryData([
        description,
        "issues",
        "list",
        pagination,
        sorting,
        columnFilters,
        globalFilter,
      ]);

      queryClient.setQueryData(
        [
          description,
          "issues",
          "list",
          pagination,
          sorting,
          columnFilters,
          globalFilter,
        ],
        (old: any) => {
          const updated = {
            ...old,
            items: onOptimisticallyUpdateIssuesAfterAssignment({
              items: old.items,
              ...variables,
            }),
          };

          return updated;
        }
      );

      return { previous };
    },
    onSuccess: async (data, variables) => {
      await queryClient.invalidateQueries({
        queryKey: [description, "issues"],
      });

      await onAssignIssuesToUserSuccess?.(variables);
    },
    onError: (err, variables, context) => {
      if (context?.previous) {
        queryClient.setQueryData(
          [
            description,
            "issues",
            "list",
            pagination,
            sorting,
            columnFilters,
            globalFilter,
          ],
          context.previous
        );
      }

      showToast();
      onAssignIssuesToUserError?.(variables);
    },
  });

  const isTableLoading = !(listQuery.data && uniqueValuesQuery.data);

  const tableColumns = useMemo(
    () =>
      [...(selectable ? ["_select" as const] : []), ...columns].map(
        (columnName) =>
          isTableLoading
            ? columnDefinitions[columnName].whenLoading
            : columnDefinitions[columnName].otherwise
      ),
    [isTableLoading, columns, selectable]
  );

  const dataForLoadingSkeletonTable = useMemo(
    () => Array(pageSize).fill({}),
    [pageSize]
  );

  const table = useReactTable({
    data: isTableLoading ? dataForLoadingSkeletonTable : listQuery.data.items,
    getRowId: (originalRow) => originalRow.id,
    columns: tableColumns,
    enableRowSelection: !!selectable,

    state: {
      rowSelection,
      pagination,
      sorting,
      columnFilters,
    },

    getCoreRowModel: getCoreRowModel(),

    manualPagination: true,
    onPaginationChange: setPagination,

    manualSorting: true,
    onSortingChange: (updaterOrValue) => {
      setSorting(updaterOrValue);
      setPagination((pagination) => ({ ...pagination, pageIndex: 0 }));
    },

    manualFiltering: true,
    onColumnFiltersChange: (updaterOrValue) => {
      setColumnFilters(updaterOrValue);
      setPagination((pagination) => ({ ...pagination, pageIndex: 0 }));
    },

    onRowSelectionChange: setRowSelection,

    columnResizeDirection: "ltr",
    columnResizeMode: "onChange",
  });

  return (
    <>
      <Stack direction="row" spacing={2} sx={{ mb: 2, ...sx }} {...etc}>
        <GlobalFilter
          value={globalFilter}
          onChange={(v) => {
            setGlobalFilter(v);
          }}
          onClear={() => {
            setGlobalFilter("");
          }}
          description={description}
        />
        {isEmpty(rowSelection) && (
          <>
            <Button
              variant="outlined"
              size="small"
              sx={{
                display: "flex",
                flexShrink: 0,
              }}
              startIcon={<AddIcon />}
              onClick={() => {
                createMonitor({}, true);
              }}
            >
              Enroll
            </Button>
            <Button
              variant="outlined"
              size="small"
              sx={{
                display: "flex",
                flexShrink: 0,
              }}
              startIcon={<MenuOpenOutlinedIcon />}
              onClick={() => {
                showMonitorList();
              }}
            >
              See all enrollments
            </Button>
          </>
        )}
        {!isEmpty(rowSelection) && (
          <>
            {selectionActions.includes("assign") && (
              <AssignButton
                ids={Object.keys(rowSelection)}
                onAssignIssues={async (
                  issueIds: string[],
                  userId: string | null | undefined,
                  userEmail: string | null | undefined
                ) => {
                  try {
                    await assignIssueToUsersMutation.mutateAsync({
                      issueIds,
                      userId,
                      userEmail,
                    });
                    table.resetRowSelection();
                  } catch (e) {
                    console.error(e);
                  }
                }}
              />
            )}
            {selectionActions.includes("export") && (
              <ExportButton
                ids={Object.keys(rowSelection)}
                getIssuesByIds={getIssuesByIds}
                onSuccess={() => table.resetRowSelection()}
                onError={() => {
                  showToast();
                }}
              />
            )}
          </>
        )}
      </Stack>

      {/* TABLE BODY */}

      <div className={cn(styles.tableWrap)}>
        <table className={cn(styles.table)}>
          <thead className={cn(styles.left)}>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header, i) => (
                  <th
                    key={header.id}
                    className={cn(styles.headercol, {
                      [styles.left]: !header.column.columnDef.meta?.resizable,
                      [styles.small]: !header.column.columnDef.meta?.resizable,
                    })}
                    style={{
                      width: header.column.columnDef.meta?.resizable
                        ? header.getSize()
                        : undefined,
                    }}
                  >
                    {header.isPlaceholder ? null : (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "0.5em",
                        }}
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                        {(header.column.columnDef.meta?.sortVariant != null ||
                          header.column.columnDef.meta?.filterStickyValues !=
                            null) && (
                          <ColumnFilterSort
                            key={header.column.id}
                            disabled={!!listQuery.isLoading}
                            active={
                              !!header.column.getIsFiltered() ||
                              !!header.column.getIsSorted()
                            }
                            onReset={() => {
                              header.column.setFilterValue(undefined);
                              header.column.clearSorting();
                            }}
                          >
                            {header.column.columnDef.meta?.sortVariant ===
                              "simple" && (
                              <SimpleSort
                                currentSort={
                                  header.column.getIsSorted() || null
                                }
                                onApply={(sort) => {
                                  if (!header.column.getCanSort()) return;

                                  if (!sort) {
                                    header.column.clearSorting();
                                    return;
                                  }

                                  if (sort === "desc") {
                                    header.column.toggleSorting(true, true);
                                    return;
                                  }

                                  if (sort === "asc") {
                                    header.column.toggleSorting(false, true);
                                    return;
                                  }
                                }}
                              />
                            )}

                            {header.column.columnDef.meta?.filterVariant ===
                              "valuePicker" && (
                              <ValuePickerFilter
                                allValues={
                                  uniqueValuesQuery.data &&
                                  header.column.id in uniqueValuesQuery.data
                                    ? uniqueValuesQuery.data[
                                        header.column
                                          .id as keyof typeof uniqueValuesQuery.data
                                      ]
                                    : undefined
                                }
                                stickyValues={
                                  header.column.columnDef.meta
                                    ?.filterStickyValues
                                }
                                selectedValues={
                                  header.column.getIsFiltered()
                                    ? (header.column.getFilterValue() as string[])
                                    : uniqueValuesQuery.data &&
                                      header.column.id in uniqueValuesQuery.data
                                    ? uniqueValuesQuery.data[
                                        header.column
                                          .id as keyof typeof uniqueValuesQuery.data
                                      ]
                                    : undefined
                                }
                                onApply={(
                                  selectedValues: string[] | null | undefined
                                ) => {
                                  const allValues = sortBy(
                                    uniq(
                                      uniqueValuesQuery.data &&
                                        header.column.id in
                                          uniqueValuesQuery.data
                                        ? uniqueValuesQuery.data[
                                            header.column
                                              .id as keyof typeof uniqueValuesQuery.data
                                          ]
                                        : []
                                    )
                                  );

                                  const selected = sortBy(
                                    uniq(selectedValues || [])
                                  );

                                  if (isEqual(allValues, selected)) {
                                    header.column.setFilterValue(undefined);
                                  } else {
                                    // we can't use header.column.setFilterValue directly
                                    // because some of the defaults/truthiness assumptions
                                    // lead to wonky ux
                                    setColumnFilters((filters) => {
                                      const updatedFilters: ColumnFiltersState =
                                        [
                                          {
                                            id: header.column.id,
                                            value: selected,
                                          },
                                        ];

                                      for (const filter of filters) {
                                        if (filter.id !== header.column.id) {
                                          updatedFilters.push(filter);
                                        }
                                      }

                                      return updatedFilters;
                                    });
                                  }
                                }}
                              />
                            )}
                            {header.column.columnDef.meta?.filterVariant ===
                              "dateThreshold" && (
                              <DateThresholdFilter
                                cutoffDate={
                                  header.column.getIsFiltered()
                                    ? (header.column.getFilterValue() as any)
                                        ?.cutoffDate
                                    : undefined
                                }
                                comparator={
                                  header.column.getIsFiltered()
                                    ? (header.column.getFilterValue() as any)
                                        ?.comparator
                                    : undefined
                                }
                                onApply={(cutoffDate, comparator) => {
                                  if (!cutoffDate) {
                                    setColumnFilters((oldFilters) =>
                                      oldFilters.filter(
                                        (f) => f.id !== header.column.id
                                      )
                                    );
                                  } else {
                                    setColumnFilters((oldFilters) => {
                                      const newFilters = oldFilters.filter(
                                        (f) => f.id !== header.column.id
                                      );
                                      newFilters.push({
                                        id: header.column.id,
                                        value: { comparator, cutoffDate },
                                      });

                                      return newFilters;
                                    });
                                  }
                                }}
                              />
                            )}
                          </ColumnFilterSort>
                        )}
                        {!!header.column.columnDef.meta?.resizable &&
                          i < headerGroup.headers.length - 1 && (
                            <div
                              onDoubleClick={() => header.column.resetSize()}
                              onMouseDown={header.getResizeHandler()}
                              onTouchStart={header.getResizeHandler()}
                              className={cn(styles.resizer, {
                                [styles.isResizing]:
                                  header.column.getIsResizing(),
                              })}
                            />
                          )}
                      </div>
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody className={styles.left}>
            {table.getRowModel().rows.map((row) => (
              <tr
                key={row.id}
                className={cn({
                  [styles.selected]: row.getIsSelected(),
                  [styles.resolved]:
                    deEmphasizeResolvedIssues &&
                    row.getValue("status") === APINotificationStatus.Resolved,
                })}
                onClick={() => {
                  if (isTableLoading) return;
                  onOpenIssueDetail?.(row.id, row.original.monitorId);
                }}
              >
                {row.getVisibleCells().map((cell) => (
                  <OverflowTooltip
                    key={cell.id}
                    tooltipEnabled={!isTableLoading}
                  >
                    <td>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  </OverflowTooltip>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
        {!table.getRowModel().rows.length && (
          <Typography className={styles.emptyMessage} variant="body2">
            No issues to show.
            <Link
              component="button"
              variant="body2"
              onClick={() => {
                createMonitor({}, true);
              }}
            >
              Enroll a merchant
            </Link>
          </Typography>
        )}
      </div>

      {/* PAGINATION */}
      <div className={styles.pagination}>
        <Pagination
          page={pagination.pageIndex + 1}
          count={
            listQuery.data
              ? Math.ceil(listQuery.data.count / listQuery.data.limit)
              : undefined
          }
          onChange={(_, page) => {
            const pageIndex = Math.max(page - 1, 0);
            table.setPageIndex(pageIndex);
          }}
          size="small"
          showFirstButton
          showLastButton
        />
        <div className={styles.rowcounts}>
          {listQuery.data != null && (
            <>
              {table.getRowCount() > 0 && (
                <>
                  {pagination.pageIndex * pagination.pageSize + 1} -{" "}
                  {Math.min(
                    (pagination.pageIndex + 1) * pagination.pageSize,
                    listQuery.data.count
                  )}{" "}
                  of {listQuery.data.count}
                </>
              )}
              {!isEmpty(rowSelection) &&
                ` (${Object.keys(rowSelection).length} selected)`}
            </>
          )}
          {listQuery.isFetching && listQuery.isPlaceholderData && (
            <CircularProgress size={12} />
          )}
        </div>
      </div>
    </>
  );
}
