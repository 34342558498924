import { Typography, Modal, Paper, Box, IconButton } from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";

import RiskToggle from "./RiskToggle";
import { MatchProfile } from ".";

export default function SentimentModal({
  open,
  setOpenModal,
  matchProfile,
  updateMatchProfile,
}: {
  open: boolean;
  setOpenModal: (arg: string | null) => void;
  matchProfile: MatchProfile;
  updateMatchProfile: any;
}) {
  return (
    <>
      <Modal
        open={open}
        onClose={() => setOpenModal(null)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Paper
          elevation={2}
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 1200,
            height: "90vh",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            overflow: "scroll",
          }}
        >
          <Box marginBottom={4} position="relative">
            <IconButton
              onClick={() => setOpenModal(null)}
              sx={{ position: "absolute", top: -20, right: -15 }}
            >
              <CloseIcon sx={{ fontSize: 50 }} />
            </IconButton>
            <Typography variant="h5" component="h3" sx={{ mb: 3 }}>
              Sentiment Risks
            </Typography>
            <RiskToggle
              label="Enable adverse media risk"
              description="This risk indicates negative news media about the company."
              value={matchProfile.enableAdverseMedia}
              onToggle={() =>
                updateMatchProfile({
                  enableAdverseMedia: !matchProfile.enableAdverseMedia,
                })
              }
            />
            <RiskToggle
              label="Enable negative business reviews"
              description="This notice indicates concerning customer reviews."
              value={matchProfile.enableNagativeBusinessReviews}
              onToggle={() =>
                updateMatchProfile({
                  enableNagativeBusinessReviews:
                    !matchProfile.enableNagativeBusinessReviews,
                })
              }
            />
          </Box>
        </Paper>
      </Modal>
    </>
  );
}
