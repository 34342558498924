import {
  Typography,
  Modal,
  Paper,
  Box,
  FormControlLabel,
  Switch,
  Button,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  IconButton,
} from "@mui/material";
import {
  ExpandMore as ExpandMoreIcon,
  Close as CloseIcon,
} from "@mui/icons-material";

import { mccData } from "./mccData";
import { MatchProfile } from ".";

function CategorySummary({
  category,
  gather,
  blockedMccCodes,
}: {
  category: string;
  gather: any;
  blockedMccCodes: string[];
}) {
  const rowCount = gather[category].length;
  const blockedCount = gather[category].filter((row: any) =>
    blockedMccCodes.includes(row.code)
  ).length;
  const color = blockedCount > 0 ? "red" : "green";

  return (
    <Typography
      variant="subtitle2"
      component="span"
      sx={{ mt: 1, color: color }}
    >
      {blockedCount} of {rowCount} blocked
    </Typography>
  );
}

export default function LocationModal({
  open,
  setOpenModal,
  matchProfile,
  updateMatchProfile,
}: {
  open: boolean;
  setOpenModal: (arg: string | null) => void;
  matchProfile: MatchProfile;
  updateMatchProfile: any;
}) {
  const toggleMccCode = (mccCode: string) => {
    const newBlocked = [...matchProfile.blockedMccCodes];
    const index = newBlocked.indexOf(mccCode);
    if (index !== -1) {
      newBlocked.splice(index, 1);
    } else {
      newBlocked.push(mccCode);
    }
    newBlocked.sort();
    updateMatchProfile({ blockedMccCodes: newBlocked });
  };

  const categories: any = new Set();
  const gather: any = {};
  mccData.forEach((row) => {
    categories.add(row.category);
    if (!(row.category in gather)) {
      gather[row.category] = [];
    }
    gather[row.category].push(row);
  });

  const blockCategory = (category: string) => {
    const catCodes = gather[category].map((row: any) => row.code);
    const newBlocked = [...matchProfile.blockedMccCodes].concat(catCodes);
    const deduped = [...new Set(newBlocked)];
    updateMatchProfile({ blockedMccCodes: deduped });
  };

  const enableCategory = (category: string) => {
    const catCodes = gather[category].map((row: any) => row.code);
    const newBlocked = [...matchProfile.blockedMccCodes].filter(
      (code) => !catCodes.includes(code)
    );
    const deduped = [...new Set(newBlocked)];
    updateMatchProfile({ blockedMccCodes: deduped });
  };

  return (
    <>
      <Modal
        open={open}
        onClose={() => setOpenModal(null)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Paper
          elevation={2}
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 1200,
            height: "90vh",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            overflow: "scroll",
          }}
        >
          <Box marginBottom={4} position="relative">
            <IconButton
              onClick={() => setOpenModal(null)}
              sx={{ position: "absolute", top: -20, right: -15 }}
            >
              <CloseIcon sx={{ fontSize: 50 }} />
            </IconButton>
            <Typography variant="h5" component="h3" sx={{ mb: 3 }}>
              Industries
            </Typography>
            {[...categories].map((category: any) => (
              <Accordion key={category}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                  key={category}
                >
                  <Typography variant="h6" component="span" sx={{ mr: 2 }}>
                    {category}
                  </Typography>
                  <CategorySummary
                    category={category}
                    gather={gather}
                    blockedMccCodes={matchProfile.blockedMccCodes}
                  />
                </AccordionSummary>
                <AccordionDetails>
                  <Box>
                    <Button
                      size="small"
                      variant="text"
                      onClick={() => blockCategory(category)}
                    >
                      block all
                    </Button>
                    <Button
                      size="small"
                      variant="text"
                      onClick={() => enableCategory(category)}
                    >
                      enable all
                    </Button>
                  </Box>
                  {gather[category as keyof typeof gather].map((row: any) => (
                    <Box key={row.code}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={matchProfile.blockedMccCodes.includes(
                              row.code
                            )}
                            onChange={() => toggleMccCode(row.code)}
                            inputProps={{ "aria-label": "controlled" }}
                          />
                        }
                        label={row.description}
                      />
                    </Box>
                  ))}
                </AccordionDetails>
              </Accordion>
            ))}
          </Box>
        </Paper>
      </Modal>
    </>
  );
}
