import { useState, useMemo, useEffect } from "react";
import {
  Button,
  Stack,
  IconButton,
  Input,
  CircularProgress,
  Box,
} from "@mui/material";
import { useInfiniteQuery } from "@tanstack/react-query";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import ClearIcon from "@mui/icons-material/Clear";
import { LoadingButton } from "@mui/lab";
import { debounce } from "lodash";

import MonitorRow from "./MonitorRow";
import styles from "./MonitorList.module.css";

import { APIMonitorType } from "../../../../types/APIMonitor";
import { useTrueBizApi } from "../../../../api";

export interface Props {
  onClose: () => void;
  onCreateMonitor: () => void;
  onDeleteMonitor: (monitorId: string) => Promise<void>;
  onEditMonitor: (monitorId: string) => void;
}

export default function MonitorList({
  onClose,
  onCreateMonitor,
  onDeleteMonitor,
  onEditMonitor,
}: Props) {
  const [monitorSearchBuffer, setMonitorSearchBuffer] = useState("");
  const [debouncedSearch, setDebouncedSearch] = useState(monitorSearchBuffer);

  const bufferSearch = useMemo(
    () => debounce(setDebouncedSearch, 250),
    [setDebouncedSearch]
  );

  useEffect(() => {
    bufferSearch(monitorSearchBuffer);
  }, [bufferSearch, monitorSearchBuffer]);

  const api = useTrueBizApi();
  const query = useInfiniteQuery({
    queryKey: ["allMonitors", { search: debouncedSearch }],
    queryFn: ({ pageParam }) =>
      api.getMonitors(
        pageParam.offset,
        pageParam.limit,
        debouncedSearch || null,
        [APIMonitorType.WebsiteContent],
        null,
        ["domain"]
      ),
    initialPageParam: { limit: 30, offset: 0 },
    getNextPageParam: (lastPage) => {
      if (lastPage.items.length < lastPage.limit) return null;

      return {
        limit: lastPage.limit,
        offset: lastPage.offset + lastPage.limit,
      };
    },
  });

  return (
    <Stack
      style={{
        width: "50vw",
        maxWidth: "425px",
        height: "100%",
        maxHeight: "100%",
        paddingLeft: "0.75em",
        paddingRight: "0.75em",
      }}
    >
      <h2>
        <IconButton onClick={() => onClose()}>
          <CloseIcon />
        </IconButton>
        Active Enrollments
      </h2>
      <Stack sx={{ overflow: "hidden" }}>
        <div style={{ display: "flex", gap: "1em" }}>
          <Input
            placeholder="Search enrollments..."
            sx={{
              display: "flex",
              flex: 1,
              minHeight: "40px",
            }}
            value={monitorSearchBuffer}
            onChange={(e) => {
              setMonitorSearchBuffer(e.target.value);
            }}
            endAdornment={
              monitorSearchBuffer === "" ? null : (
                <IconButton
                  onClick={() => {
                    setMonitorSearchBuffer("");
                  }}
                >
                  <ClearIcon />
                </IconButton>
              )
            }
          />
          <Button
            startIcon={<AddIcon />}
            variant="outlined"
            size="small"
            onClick={() => {
              onCreateMonitor();
            }}
          >
            Enroll
          </Button>
        </div>
        <div
          className={styles.monitorsList}
          style={{
            overflow: query.isPending ? "hidden" : "auto",
            paddingBottom: "0.5em",
          }}
        >
          {query.isPending ? (
            <Box
              width="100%"
              display="flex"
              alignItems="center"
              justifyContent="center"
              mt={2}
            >
              <CircularProgress />
            </Box>
          ) : query.isError ? (
            "There was a problem loading the enrollments lists. Please try again soon."
          ) : (
            query.data.pages.flatMap((page) =>
              page.items.map((monitor) => (
                <MonitorRow
                  key={monitor.id}
                  monitor={monitor}
                  onEdit={async (id) => {
                    onEditMonitor(id);
                  }}
                  onDelete={async (id) => {
                    await onDeleteMonitor(id);
                  }}
                />
              ))
            )
          )}
          {query.hasNextPage && (
            <LoadingButton
              disabled={query.isFetchingNextPage}
              loading={query.isFetchingNextPage}
              onClick={() => query.fetchNextPage()}
              variant="outlined"
              sx={{ width: "100%", mt: 1 }}
            >
              Load More
            </LoadingButton>
          )}
        </div>
      </Stack>
    </Stack>
  );
}
