import { useFormik } from "formik";
import {
  Button,
  Divider,
  Stack,
  Typography,
  TextField,
  Alert,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";

import { MonitoringCreateFormSchema } from "./schema";
import MonitoringIcon from "./monitoring-icon.svg";
import { Prefill } from "../types";
import APIMonitor, {
  APICreateMonitorInput,
} from "../../../../types/APIMonitor";
import APICompanyMatchProfile from "../../../../types/APICompanyMatchProfile";

export type FormState = ReturnType<
  typeof MonitoringCreateFormSchema.validateSync
>;

export interface Props {
  onCancel: () => void;
  onSuccess: (domain: string, nextRun?: string | null | undefined) => void;
  onError: (domain: string, reason?: string) => void;
  onCreateMonitor: (formData: APICreateMonitorInput) => Promise<APIMonitor>;
  prefill?: Partial<Prefill>;
  matchProfiles: APICompanyMatchProfile[];
}

export default function MonitorCreateForm({
  onCancel,
  onSuccess,
  onError,
  onCreateMonitor,
  prefill,
  matchProfiles,
}: Props) {
  const formik = useFormik({
    initialValues: {
      domain: "",
      user_description: "",
      mcc: "",
      external_ref_id: "",
      profile_id: "",
      ...(prefill || {}),
    },
    onSubmit: async (values: FormState, { resetForm }) => {
      try {
        // Formik will not automatically honor yup's value transforms which we need
        // to potentially clean and extract the domain/hostname from a url
        const transformedValues = MonitoringCreateFormSchema.cast(values);
        const result = await onCreateMonitor(transformedValues as any);
        resetForm();
        onSuccess(transformedValues.domain, result.not_before);
      } catch (e) {
        let description = "An unknown error";
        try {
          if (e instanceof Response) {
            const j = await e.json();
            description = j?.message || JSON.stringify(j);
          }
        } catch (e) {
          // pass
        }

        onError(values.domain, description);
      }
    },
    validationSchema: MonitoringCreateFormSchema,
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Stack
        sx={{
          alignItems: "center",
          mb: "2em",
        }}
      >
        <img src={MonitoringIcon} width={100} alt="" />
        <Typography>
          Enroll a merchant in
          <br />
          TrueBiz monitoring
        </Typography>
      </Stack>
      <Stack
        sx={{
          gap: "1em",
        }}
      >
        <FormControl>
          <TextField
            fullWidth
            name="domain"
            label="Domain (required)"
            required
            onChange={formik.handleChange}
            value={formik.values.domain}
            error={!!(formik.touched.domain && formik.errors.domain)}
            autoFocus
          />
          {formik.errors.domain && formik.touched.domain && (
            <Alert severity="error" sx={{ mt: 1, mb: 1 }}>
              {formik.errors.domain}
            </Alert>
          )}
        </FormControl>

        {/* <FormControl>
          <TextField
            fullWidth
            name="user_description"
            label="Merchant Description"
            onChange={formik.handleChange}
            value={formik.values.user_description}
            error={
              !!(
                formik.touched.user_description &&
                formik.errors.user_description
              )
            }
          />
          {formik.errors.user_description &&
            formik.touched.user_description && (
              <Alert severity="error" sx={{ mt: 1, mb: 1 }}>
                {formik.errors.user_description}
              </Alert>
            )}
        </FormControl> */}

        {/* TODO: make this a searchable dropdown w descriptions */}
        {/* <FormControl>
          <TextField
            fullWidth
            name="mcc"
            label="Merchant MCC (required)"
            required
            onChange={formik.handleChange}
            value={formik.values.mcc}
            error={!!(formik.touched.mcc && formik.errors.mcc)}
          />
          {formik.errors.mcc && formik.touched.mcc && (
            <Alert severity="error" sx={{ mt: 1, mb: 1 }}>
              {formik.errors.mcc}
            </Alert>
          )}
        </FormControl> */}

        {matchProfiles?.length > 0 && (
          <FormControl sx={{ width: "100%" }}>
            <InputLabel
              id="match-profile-label"
              shrink
              sx={{ background: "white" }}
            >
              Risk Profile
            </InputLabel>
            <Select
              labelId="match-profile-label"
              label="Risk Profile"
              id="match-profile-select"
              defaultValue={formik.initialValues.profile_id}
              value={formik.values.profile_id ? formik.values.profile_id : ""}
              onChange={formik.handleChange}
              name="profile_id"
              displayEmpty
            >
              <MenuItem value={""}>TrueBiz Default</MenuItem>
              {matchProfiles?.map((profile) => (
                <MenuItem key={profile.id} value={profile.id}>
                  {profile.name}
                </MenuItem>
              ))}
            </Select>
            {formik.errors.profile_id && formik.touched.profile_id && (
              <Alert severity="error" sx={{ mt: 1, mb: 1 }}>
                {formik.errors.profile_id}
              </Alert>
            )}
          </FormControl>
        )}

        <FormControl>
          <TextField
            fullWidth
            name="external_ref_id"
            label="External Reference ID"
            onChange={formik.handleChange}
            value={formik.values.external_ref_id}
            error={
              !!(
                formik.touched.external_ref_id && formik.errors.external_ref_id
              )
            }
          />
          {formik.errors.external_ref_id && formik.touched.external_ref_id && (
            <Alert severity="error" sx={{ mt: 1, mb: 1 }}>
              {formik.errors.external_ref_id}
            </Alert>
          )}
        </FormControl>
        <Divider />
      </Stack>
      <div
        style={{
          display: "flex",
          justifyContent: "end",
          width: "100%",
          gap: "1.5em",
          marginTop: "1.5em",
        }}
      >
        <Button
          variant="outlined"
          onClick={() => {
            formik.resetForm();
            onCancel();
          }}
          disabled={formik.isSubmitting}
        >
          Cancel
        </Button>
        <LoadingButton
          variant="contained"
          type="submit"
          loading={formik.isSubmitting}
        >
          Enroll Merchant
        </LoadingButton>
      </div>
    </form>
  );
}
