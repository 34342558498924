import { HTMLProps, ReactNode, useMemo, useState } from "react";
import cn from "classnames";
import Highlighter from "react-highlight-words";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useOverflowDetector } from "react-detectable-overflow";

import {
  Box,
  Divider,
  Skeleton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { orderBy, uniq, zip } from "lodash";
import { useInView } from "react-intersection-observer";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import LoadingButton from "@mui/lab/LoadingButton";

import {
  formatDate,
  formatDateTime,
  formatUser,
  PENDING_DATETIME,
} from "./utils";

import trimHighlightContext from "./trimHighlightContext";

import LinkButton from "./LinkButton";
import Whisker from "./Whisker";
import AssignButton from "./AssignButton";
import ResolveButton from "./ResolveButton";

import styles from "./IssueDetail.module.css";

import {
  extractMonitorIdFromNotificationMonitorLink,
  messageToAlert,
} from "../views/utils";

import { APIEnquirySiteContentRemark } from "../../../types/APIEnquiry";

import APINotification, {
  APINotificationStatus,
} from "../../../types/APINotification";

import { useTrueBizApi } from "../../../api";

import APINotificationActivity, {
  APINotificationActivityDetailsType,
} from "../../../types/APINotificationActivity";

import APINotificationComment from "../../../types/APINotificationComment";

import APIUser from "../../../types/APIUser";

import { useIdentity } from "../../IdentityProvider";
import { useShowNetworkFailureToast } from "../../NetworkFailureToast";

const createTextFragmentLink = (url: string, fragmentText: string) => {
  const parsed = new URL(url);
  if (parsed.hash) {
    return url;
  }

  const fragmentWords = fragmentText.split(" ");
  const firstTwo = fragmentWords.slice(0, 2);
  const lastTwo = fragmentWords.slice(-2);

  const textFragmentHash = `#:~:text=${encodeURIComponent(
    firstTwo.join(" ")
  )},${encodeURIComponent(lastTwo.join(" "))}`;
  parsed.hash = textFragmentHash;

  return parsed.toString();
};

const Highlight = ({
  children,
}: {
  children: ReactNode;
  highlightIndex: number;
}) => <strong className={styles["highlighted-text"]}>{children}</strong>;

export interface Props extends Exclude<HTMLProps<HTMLDivElement>, "children"> {
  issue: APINotification;
  relatedContentRemarks: APIEnquirySiteContentRemark[];
  suspendLoading?: boolean;
  focused?: boolean;
}

export default function IssueDetail({
  issue,
  relatedContentRemarks,
  suspendLoading = false,
  focused = false,
  className,
  ...etc
}: Props) {
  const api = useTrueBizApi();
  const identity = useIdentity();
  const queryClient = useQueryClient();
  const showToast = useShowNetworkFailureToast();
  const [expanded, setExpanded] = useState(false);

  const [commentText, setCommentText] = useState("");

  const { ref: overflowRef, overflow } = useOverflowDetector();

  // if loading is blocked, don't do anything
  // otherwise only load data if we're visible
  const { ref: inViewRef, inView } = useInView();

  const issueCommentsQuery = useQuery({
    queryKey: ["getNotificationComments", issue.id],
    queryFn: () => api.getNotificationComments(issue.id),
    enabled: !suspendLoading && inView,
  });

  const issueActivityQuery = useQuery({
    queryKey: ["getNotificationActivity", issue.id],
    queryFn: () => api.getNotificationActivity(issue.id),
    enabled: !suspendLoading && inView,
  });

  const allPeopleQuery = useQuery({
    queryKey: ["allPeople"],
    queryFn: () => api.getNotificationsEligibleAssignees(),
  });

  const commentMutation = useMutation({
    mutationKey: ["issue", "comment", issue.id, commentText],
    mutationFn: () => api.postNewNotificationComment(issue.id, commentText),
    onSuccess: () => {
      setCommentText("");

      queryClient.invalidateQueries({
        queryKey: ["getNotificationComments", issue.id],
      });
    },
    onMutate: async () => {
      await queryClient.cancelQueries({
        queryKey: ["getNotificationComments", issue.id],
      });
      const previous = queryClient.getQueryData([
        "getNotificationComments",
        issue.id,
      ]);

      queryClient.setQueryData(
        ["getNotificationComments", issue.id],
        (old: APINotificationComment[]) => {
          const now = new Date().toISOString();
          const me = (allPeopleQuery.data || []).filter(
            (p) => p.email != null && p.email === identity.user?.email
          )[0];

          const updated = [
            ...old,
            {
              id: "PENDING",
              created_at: now,
              updated_at: now,
              message: commentText,
              commenter: me || { id: "PENDING", email: null },
            },
          ];
          return updated;
        }
      );

      return { previous };
    },
    onError: (err, variables, context) => {
      if (context?.previous) {
        queryClient.setQueryData(["issueActivity", issue.id], context.previous);
      }
      showToast();
    },
  });

  const issueActivityTimeline = useMemo(() => {
    const activity = issueActivityQuery.data || [];
    const comments = issueCommentsQuery.data || [];

    type Item =
      | { type: "created" }
      | {
          type: "resolved";
          body: {
            acknowledged_by_email?: string | null | undefined;
            acknowledged_reason?: string | null | undefined;
            acknowledged_notes?: string | null | undefined;
          };
        }
      | { type: "activity"; body: APINotificationActivity }
      | { type: "comment"; body: APINotificationComment };

    const tl: { id: UUID; timestamp: ISODateTime; item: Item }[] = [
      {
        id: `${issue.id}_created`,
        timestamp: issue.created_at,
        item: { type: "created" },
      },
    ];

    if (issue.acknowledged_at) {
      tl.push({
        id: `${issue.id}_resolved`,
        timestamp: issue.acknowledged_at,
        item: {
          type: "resolved",
          body: {
            acknowledged_by_email: issue.acknowledged_by,
            acknowledged_reason: issue.acknowledged_reason,
            acknowledged_notes: issue.acknowledged_notes,
          },
        },
      });
    }

    for (const a of activity) {
      tl.push({
        id: a.id,
        timestamp: a.created_at,
        item: { type: "activity", body: a },
      });
    }

    for (const c of comments) {
      tl.push({
        id: c.id,
        timestamp: c.id === "PENDING" ? PENDING_DATETIME : c.created_at,
        item: { type: "comment", body: c },
      });
    }

    return orderBy(tl, ["timestamp"], ["asc"]);
  }, [issueActivityQuery.data, issueCommentsQuery.data, issue]);

  return (
    <div
      {...etc}
      className={cn(styles.issue, className, {
        [styles.unresolved]: issue.status !== APINotificationStatus.Resolved,
        [styles.focused]: focused,
      })}
      ref={inViewRef}
      onClick={() => setExpanded(true)}
    >
      <div className={styles.badge}>
        <div className={styles.date}>{formatDateTime(issue.created_at)}</div>
        <div className={styles.status}>{issue.status}</div>
      </div>
      <div className={styles.controls}>
        <div className={styles.assignee}>
          Assignee:{" "}
          <span
            className={cn(styles.name, { [styles.empty]: !issue.assigned_to })}
          >
            {!issue.assigned_to ? "unassigned" : formatUser(issue.assigned_to)}
          </span>
        </div>
        <div className={styles.actionButtons}>
          <LinkButton title="Copy link" issueId={issue.id} />
          <AssignButton
            title="Assign issue"
            issueId={issue.id}
            currentAssigneeId={issue.assigned_to?.id}
            monitorId={extractMonitorIdFromNotificationMonitorLink(
              issue.links.monitor
            )}
          />
          <ResolveButton
            title={
              issue.status === APINotificationStatus.Resolved
                ? "This issue has already been resolved"
                : "Resolve issue"
            }
            issueId={issue.id}
            monitorId={extractMonitorIdFromNotificationMonitorLink(
              issue.links.monitor
            )}
            disabled={issue.status === APINotificationStatus.Resolved}
          />
        </div>
      </div>

      <div
        className={styles.details}
        style={
          !expanded
            ? { height: "450px", overflow: "hidden" }
            : { minHeight: "450px", height: "auto" }
        }
        ref={overflowRef as any}
      >
        <div className={styles.summary}>
          <div className={styles.type}>
            Alert type{issue.messages.length === 1 ? "" : "s"}:{" "}
            <strong>
              {uniq(orderBy(issue.messages.map(messageToAlert))).join(", ") ||
                "unknown"}
            </strong>
          </div>
          {relatedContentRemarks.length === 0 && (
            <Typography>
              No further information about this issue is currently available.
            </Typography>
          )}
          {relatedContentRemarks.map((remark, idx) => (
            <Stack key={idx}>
              <div>
                Content related to this was found
                {(remark.data.urls || []).length > 0 &&
                  " on the following pages:"}
              </div>
              <ul
                style={{
                  paddingLeft: "1em",
                  paddingRight: "2.75em",
                  wordBreak: "break-word",
                }}
              >
                {/* If we can't display any snippets/context, just show the url */}
                {(remark.data.contexts || []).length === 0 ||
                (remark.data.snippets || []).length === 0
                  ? (remark.data.urls || []).map((url, i) => (
                      <li key={`${url}__${i}`} className={styles.citation}>
                        <a href={url} target="_blank" rel="noopener noreferrer">
                          {url}
                        </a>
                      </li>
                    ))
                  : (remark.data.urls || []).map((url, i) =>
                      zip(remark.data.snippets, remark.data.contexts).map(
                        ([text, context], i) =>
                          !text || !context ? null : (
                            <li
                              key={`${url}__${i}`}
                              className={styles.citation}
                            >
                              <a
                                href={createTextFragmentLink(url, text)}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {url}
                              </a>
                              <div className={styles.context}>
                                <div className={styles.quote}>
                                  <Highlighter
                                    searchWords={[text]}
                                    textToHighlight={trimHighlightContext(
                                      context,
                                      [text]
                                    )}
                                    highlightTag={Highlight}
                                    autoEscape
                                  />
                                </div>
                                <div className={styles.firstSeen}>
                                  {/* TODO: actually track this on the backend */}
                                  First seen {formatDate(issue.created_at)}
                                </div>
                              </div>
                            </li>
                          )
                      )
                    )}
              </ul>
            </Stack>
          ))}
        </div>
        <Divider orientation="vertical" flexItem />
        <Box
          pl={2}
          pr={2}
          flex="1"
          maxWidth="45%"
          marginLeft="auto"
          marginRight="auto"
        >
          <Typography variant="subtitle1" mb={2} fontWeight={600}>
            Comments and activity
          </Typography>
          {issueCommentsQuery.isLoading ||
          issueActivityQuery.isLoading ||
          allPeopleQuery.isLoading ? (
            <Stack mb={2}>
              <Skeleton />
              <Skeleton />
              <Whisker />
              <Skeleton />
            </Stack>
          ) : (
            <Stack minHeight="25px" gap="0.25em">
              {issueActivityTimeline
                .map((e) => {
                  if (e.item.type === "created") {
                    return (
                      <Stack key={e.id}>
                        <Stack
                          direction="row"
                          gap="1em"
                          alignItems="baseline"
                          justifyContent="space-between"
                        >
                          <Typography variant="subtitle2">
                            Issue reported by TrueBiz
                          </Typography>
                          <Typography
                            className={styles.timestamp}
                            variant="subtitle2"
                          >
                            {formatDateTime(e.timestamp)}
                          </Typography>
                        </Stack>
                      </Stack>
                    );
                  }

                  if (e.item.type === "resolved") {
                    return (
                      <Stack key={e.id}>
                        <Stack
                          direction="row"
                          gap="1em"
                          alignItems="baseline"
                          justifyContent="space-between"
                        >
                          <Typography variant="subtitle2">
                            Issue resolved
                          </Typography>
                          <Typography
                            className={styles.timestamp}
                            variant="subtitle2"
                          >
                            {formatDateTime(e.timestamp)}
                          </Typography>
                        </Stack>
                        <Typography
                          mt={1}
                          p={1.5}
                          mb={1.5}
                          border="4px double #756f8b"
                          borderRadius="4px"
                          component="div"
                        >
                          <Typography>
                            <strong>
                              {e.item.body.acknowledged_by_email ||
                                "A TrueBiz user"}
                            </strong>{" "}
                            resolved the issue as &ldquo;
                            {e.item.body.acknowledged_reason || "resolved"}
                            &rdquo;
                          </Typography>
                          {e.item.body.acknowledged_notes && (
                            <Typography>
                              {e.item.body.acknowledged_notes}
                            </Typography>
                          )}
                        </Typography>
                      </Stack>
                    );
                  }

                  if (e.item.type === "comment") {
                    return (
                      <Stack key={e.id}>
                        <Stack
                          direction="row"
                          gap="1em"
                          alignItems="baseline"
                          justifyContent="space-between"
                        >
                          <Typography variant="subtitle2">
                            <strong>
                              {e.item.body.commenter.email || "A TrueBizUser"}
                            </strong>{" "}
                            commented:
                          </Typography>
                          <Typography
                            className={styles.timestamp}
                            variant="subtitle2"
                          >
                            {formatDateTime(e.timestamp)}
                          </Typography>
                        </Stack>
                        <div
                          className={cn(styles.commentBody, {
                            [styles.pending]: e.item.body.id === "PENDING",
                          })}
                        >
                          {e.item.body.message}
                        </div>
                      </Stack>
                    );
                  }

                  if (e.item.type === "activity") {
                    if (
                      e.item.body.action_details_type ===
                      APINotificationActivityDetailsType.NotificationAssignmentDetails
                    ) {
                      const actingUserId =
                        e.item.body.action_details?.assigning_user_id;
                      const actingUser: APIUser | undefined = (
                        allPeopleQuery.data || []
                      ).filter((p) => p.id === actingUserId)[0];

                      const targetUserId =
                        e.item.body.action_details?.assigned_user_id;
                      const targetUser: APIUser | undefined = (
                        allPeopleQuery.data || []
                      ).filter((p) => p.id === targetUserId)[0];

                      if (!targetUser) {
                        return (
                          <Stack key={e.id}>
                            <Stack
                              direction="row"
                              gap="1em"
                              alignItems="baseline"
                              justifyContent="space-between"
                            >
                              <Typography variant="subtitle2">
                                <strong>
                                  {actingUser.email || "A TrueBiz user"}
                                </strong>{" "}
                                unassigned issue
                              </Typography>
                              <Typography
                                className={styles.timestamp}
                                variant="subtitle2"
                              >
                                {formatDateTime(e.timestamp)}
                              </Typography>
                            </Stack>
                          </Stack>
                        );
                      }

                      if (
                        actingUser &&
                        actingUser.id &&
                        targetUser &&
                        targetUser.id &&
                        actingUser.id === targetUser.id
                      ) {
                        return (
                          <Stack key={e.id}>
                            <Stack
                              direction="row"
                              gap="1em"
                              alignItems="baseline"
                              justifyContent="space-between"
                            >
                              <Typography variant="subtitle2">
                                <strong>
                                  {actingUser.email || "A TrueBiz user"}
                                </strong>{" "}
                                self-assigned issue
                              </Typography>
                              <Typography
                                className={styles.timestamp}
                                variant="subtitle2"
                              >
                                {formatDateTime(e.timestamp)}
                              </Typography>
                            </Stack>
                          </Stack>
                        );
                      }

                      if (actingUser && targetUser) {
                        return (
                          <Stack key={e.id}>
                            <Stack
                              direction="row"
                              gap="1em"
                              alignItems="baseline"
                              justifyContent="space-between"
                            >
                              <Typography variant="subtitle2">
                                <strong>
                                  {actingUser.email || "A TrueBiz user"}
                                </strong>{" "}
                                assigned issue to{" "}
                                <strong>
                                  {targetUser.email || "a TrueBiz user"}
                                </strong>
                              </Typography>
                              <Typography
                                className={styles.timestamp}
                                variant="subtitle2"
                              >
                                {formatDateTime(e.timestamp)}
                              </Typography>
                            </Stack>
                          </Stack>
                        );
                      }
                    }
                  }

                  return null;
                })
                .flatMap((el, i, els) =>
                  i === els.length - 1 || !el ? [el] : [el, <Whisker key={i} />]
                )}
            </Stack>
          )}
          <Stack mt={2}>
            <Stack>
              <TextField
                sx={{ width: "100%" }}
                multiline
                rows={3}
                value={commentText}
                onChange={(e) => {
                  setCommentText(e.target.value);
                }}
                disabled={
                  commentMutation.isPending ||
                  allPeopleQuery.isLoading ||
                  issueActivityQuery.isLoading ||
                  issueCommentsQuery.isLoading
                }
              />
            </Stack>
            <LoadingButton
              variant="outlined"
              sx={{
                display: "block",
                marginTop: "1em",
                marginLeft: "auto",
              }}
              disabled={
                allPeopleQuery.isLoading ||
                issueActivityQuery.isLoading ||
                issueCommentsQuery.isLoading
              }
              loading={commentMutation.isPending}
              onClick={async () => {
                if (!commentText) return;
                await commentMutation.mutateAsync();
              }}
            >
              Add Comment
            </LoadingButton>
          </Stack>
        </Box>
      </div>

      {overflow && (
        <div className={styles.seeMore} onClick={() => setExpanded(true)}>
          <ExpandMoreIcon /> See more&hellip;
        </div>
      )}
    </div>
  );
}
